
  import Vue from 'vue'

  import { RRule, rrulestr } from 'rrule'

  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import PriceService from '@/services/PriceService';
  import { extractErrorMessage, reportTypedColumns } from '@/utils/Util';
  import DeviceService from '@/services/DeviceService';
  import PropertyService from '@/services/PropertyService';
  import ConnectorService from '@/services/ConnectorService';
import TagService from '@/services/TagService';

  const frequencyInputsTable = {
    yearly: {
      rrule: RRule.YEARLY,
      until: true,
      interval: true,
      month: true,
      day_of_month: true,
      day_of_the_week: false
    },
    monthly: {
      rrule: RRule.MONTHLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: true,
      day_of_the_week: false
    },
    weekly: {
      rrule: RRule.WEEKLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: true
    },
    daily: {
      rrule: RRule.DAILY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    once: {
      rrule: null,
      until: false,
      interval: false,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    }
  };

  const range = (n: number) => Array.from({ length: n }, (v, k) => k + 1);
  const thirty1 = range(31);
  const thirty = range(30);
  const twenty9 = range(29);

  const daysPerMonth = {
    January: thirty1,
    February: twenty9,
    March: thirty1,
    April: thirty,
    May: thirty1,
    June: thirty,
    July: thirty1,
    August: thirty1,
    September: thirty,
    October: thirty1,
    November: thirty,
    December: thirty1
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  export default Vue.extend({
    name: 'ReportInfoDialog',

    components: {
      EntityMetaDialog,
    },
    props: ['report'],
    data: (vm: any): any => ({
        frequencyInputsTable,
        daysPerMonth,
        weekdays,
        repetitionSelection: "",
        rrule: {
          interval: null,
          start: "",
          frequency: null,
          until_num: null,
          month: null,
          daysOfTheMonth: [],
          daysOfTheWeek: [],
          until_date: null
        },
        dialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        reportsPrice: undefined,
        // report config
        loadingDevices: false,
        loadingProperties: false,
        loadingConnectors: false,
        loadingUsers: false,
        loadingTags: false,

        users: [] as any[],
        devices: [] as any[],
        connectors: [] as any[],
        properties: [] as any[],
        tags: [] as any[],
        fileFormats: [
          { name: "CSV", value: "csv" },
          { name: "XLSX", value: "xlsx" }
        ],
        dateFormats: [
          { name: "ISO", value: "iso" },
          { name: "Locale", value: "locale" },
          { name: "en-US", value: "en-us" },
        ],
        locales: [
          { name: vm.$t('locales.english'), value: "en" },
          { name: vm.$t('locales.german'), value: "de" },        
        ],
        aggregateMethods: [
            { text: vm.$t('aggregation.last'), value: "LAST" },  
            { text: vm.$t('aggregation.first'), value: "FIRST" },  
            { text: vm.$t('aggregation.min'), value: "MIN" },  
            { text: vm.$t('aggregation.max'), value: "MAX" },  
            { text: vm.$t('aggregation.sum'), value: "SUM" },  
            { text: vm.$t('aggregation.avg'), value: "AVG" },  
        ],
        reportTypes: [
          { name: vm.$t('report.deviceReport'), value: "device-report" },
          { name: vm.$t('report.readingReport'), value: "reading-report" },
          { name: vm.$t('report.meteringReport'), value: "metering-report" },
          { name: vm.$t('report.billingReport'), value: "billing-report" }
        ],
        billingTypes: [
          { text: vm.$t('timeUnit.year'), value: "YEARLY" },  
          { text: vm.$t('timeUnit.month'), value: "MONTHLY" }, 
        ],
        timespanUnits: [
          { text: vm.$t('timeUnit.months'), value: "MONTHS" },  
          { text: vm.$t('timeUnit.weeks'), value: "WEEKS" }, 
        ]
    }),
    created() {
      console.log(this.report);
    },
    methods: {
      close() {
        this.dialog = false;
      },
      clear() {
        this.rrule = {
          interval: null,
          start: "",
          frequency: null,
          until_num: null,
          month: null,
          daysOfTheMonth: [],
          daysOfTheWeek: [],
          until_date: null
        };
        this.alertVisible = false;
        this.loading = false;
        this.reportsPrice = undefined;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      parseRRule() {
        let _rrule = rrulestr(this.report.rrule);

        this.rrule.start = _rrule.options.dtstart;

        if(_rrule.options.freq === RRule.YEARLY) {
          if(_rrule.options.count === 1) {
            // this means its once
            this.rrule.frequency = 'Once';
          } else {
            this.rrule.frequency = 'Yearly';
          }
        } else if(_rrule.options.freq === RRule.MONTHLY) {
          this.rrule.frequency = 'Monthly';
        } else if(_rrule.options.freq === RRule.WEEKLY) {
          this.rrule.frequency = 'Weekly';
        } else if(_rrule.options.freq === RRule.DAILY) {
          this.rrule.frequency = 'Daily';
        } else {
          // unsupported
          this.rrule.frequency = 'Once';
        }

        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].interval && _rrule.options.interval) {
          this.rrule.interval = Number(_rrule.options.interval);
        }
        if(_rrule.options.count && this.rrule.frequency !== 'Once') {
          this.repetitionSelection = 'until_count';
          this.rrule.until_num = Number(_rrule.options.count);
        }
        if(_rrule.options.until) {
          this.repetitionSelection = 'until_date';
          this.rrule.until_date = new Date(_rrule.options.until).toISOString();
        }
        if(!this.rrule.until_num && !this.rrule.until_date) {
          // case its indefinitly
          this.repetitionSelection = 'forever';
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month && _rrule.options.bymonth) {
          this.rrule.month = _rrule.options.bymonth;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_month && _rrule.options.bymonthday) {
          this.rrule.daysOfTheMonth = _rrule.options.bymonthday;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_the_week && _rrule.options.byweekday) {
          this.rrule.daysOfTheWeek = _rrule.options.byweekday.map((day: number) => this.weekdays[day]);
        }
      },
      getAssociatedPrice() {
        this.loadingPrice = true;

        let params = {} as any;

        params.type = 'platform';
        params.subtype = 'reports';
        params.tenantId = this.$root.$store.state.session.selectedTenant.uuid;

        PriceService.getAllCollectionPages(params).then((prices: any) => {
          this.loadingPrice = false;
          if(prices.length > 0) {
            this.reportsPrice = prices[0];
          }
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('price.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingPrice = false;  
        });
      },
      async getDevices() {
        this.devices = [];
        this.loadingDevices = true;
        
        try {
          let deviceParams = {} as any;
          
          this.devices = await DeviceService.getAllCollectionPages(this.report.config.attributes.filters.tenantIds[0], deviceParams);
          this.loadingDevices = false;
        } catch(err: any) {
            console.log(err, err.response); 
            this.loadingDevices = false;     
        }
      },
      getTags() {
        this.tags = [];
        this.loadingTags = true;
        
        TagService.getAllCollectionPages(this.report.config.attributes.filters.tenantIds[0]).then((tags: any[]) => {
            this.loadingTags = false;

            this.tags = tags;
        }).catch((err: any) => {
            console.log(err, err.response); 
            this.loadingTags = false;     
        });
      },
      async getProperties() {
        this.properties = [];
        this.loadingProperties = true;
        
        try {
          const properties = await PropertyService.getAllCollectionPages(this.report.config.attributes.filters.tenantIds[0]);

          this.properties = properties;
          this.loadingProperties = false;
        } catch(err: any) {
          console.log(err, err.response); 
          this.loadingProperties = false;     
        }
      },
      async getConnectors() {
        this.connectors = [];
        this.loadingConnectors = true;
        
        ConnectorService.getAllCollectionPages(this.report.config.attributes.filters.tenantIds[0]).then((connectors: any[]) => {
          this.loadingConnectors = false;

          this.connectors = connectors;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loadingConnectors = false;    
        });
      },
      toCurrencyString(price: number) {
        return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
    },
    watch: {
      async dialog(value: boolean) {
        if(value) {
          // this.getAssociatedPrice();
          this.parseRRule();

          if (this.report.config?.attributes?.filters?.deviceIds || this.report.config?.attributes?.filters?.device?.deviceIds) {
            this.getDevices();
          }
          if (this.report.config?.attributes?.filters?.device?.tagIds) {
            this.getTags();
          }
          if (this.report.config?.attributes?.filters?.propertyIds) {
            this.getProperties();
          }
          if (this.report.config?.attributes?.filters?.connectorIds) {
            this.getConnectors();
          }
        } else {
          this.clear();
        }
      }
    },
    computed: {
      firstExecutionDate() {
        return this.report.rrule ? new Date(this.parsedRRule.all((date: Date, i: number) => i < 1)[0]) : undefined;
      },
      parsedRRule() {
        return rrulestr(this.report.rrule);
      },
      firstTimespanExample() {
        if(this.firstExecutionDate && (this.report.config.type === 'device-report' || this.report.config.type === 'reading-report')) {
          if(this.report.orientByCalendarBounds) {
              // orient by calendar bounds
              // depending on timespanUnit, get the end-date of the timespan
              // then subtract the timespanValue timespan to get start-date
              if(this.report.timespanUnit === 'WEEKS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCHours(0, 0, 0, 0);
                  end.setUTCDate(end.getUTCDate() - end.getUTCDay() + (end.getUTCDay() == 0 ? -6:1));
                  let start = new Date(end);
                  start.setUTCDate(start.getUTCDate()-(this.report.timespanValue*7));

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              } else if (this.report.timespanUnit === 'MONTHS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCDate(1);
                  end.setUTCHours(0, 0, 0, 0);
                  let start = new Date(end);
                  start.setMonth(start.getMonth()-this.report.timespanValue);

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              }
          } else {
              // ignore calendar bounds
              // substract the timespanValue directly from current date
              if(this.report.timespanUnit === 'WEEKS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCHours(0, 0, 0, 0);
                  let start = new Date(end);
                  start.setUTCDate(start.getUTCDate()-(this.report.timespanValue*7));

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              } else if (this.report.timespanUnit === 'MONTHS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCHours(0, 0, 0, 0);
                  let start = new Date(end);
                  start.setUTCMonth(start.getUTCMonth()-this.report.timespanValue);

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              }
          }
        }

        return '';
      },
      reportsPriceString() {
        return this.reportsPrice ? this.toCurrencyString(this.reportsPrice.basePrice) : null;
      },
      intervalHint() {
        const rrule = this.rrule;
        return `1 means every ${
          rrule.frequency === "Daily" ? "Day" : rrule.frequency.slice(0, -2)
        }, 2 is every other ${
          rrule.frequency === "Daily" ? "Days" : rrule.frequency.slice(0, -2)
        }`;
      },
      frequencies(): any {
        // TODO add i18n translation
        const keys = Object.keys(frequencyInputsTable);
        return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
      },
      months(): any { 
        const keys = Object.keys(daysPerMonth);
        return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
      },
      requiredError(): any {
          return this.$t('required');
      },
      typedColumns() {
        return reportTypedColumns(this.report.config.type);
      },
      years(): any[] {
        const currentYear = new Date(Date.now()).getFullYear();
        let yl = [];
        for (let i = 2000; i <= currentYear; i++) {
            yl.push(i);
        }
        return yl;
      },
    }
  })
