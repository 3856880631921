
  import Vue from 'vue'
  import FilterChipTemplate from './FilterChipTemplate.vue';

  export default Vue.extend({
    name: 'StringFilter',

    components: {
      FilterChipTemplate
    },
    props: ['value', 'label', 'attributes', 'mandatory', 'always'],
    data: (_this: any): any => ({
      rules: [
        (v: string) => !!v || _this.requiredError
      ],
      localString: _this.value[_this.attributes['value']],
      localFilters: _this.value as any,
      menu: false,
    }),
    created() {
      // s
    },
    methods: {
      onSave() {
        this.localFilters[this.attributes['value']] = this.localString;
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onReset() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onChipRemove() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.$emit('delete');
      }
    },
    watch: {
      value() {
        this.localFilters = { ...this.value };
        this.localString = this.localFilters[this.attributes['value']];
      },
      menu() {
        if(!this.menu) {
          this.localString = this.localFilters[this.attributes['value']];
        }
      }
    },
    computed: {
      filterRepr(): any {
        return this.localFilters[this.attributes['value']] ?? '';
      },
      isValid(): any {
        return this.mandatory ? this.rules.reduce((acc: boolean, rule: any) => acc ? rule(this.localString)===true : acc, true) : true;
      },
      requiredError(): any {
        return this.$t('required');
      },
    }
  })
