
  import Vue from 'vue'
  import DeviceService from '@/services/DeviceService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'ChartCreateDialog',

    components: {
    },
    props: ['device', 'groups', 'group'],
    data: (vm: any): any => ({
        localDialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        devices: [] as any[],
        entry: {
          group: "",
          devices: [],
        } as any,
    }),
    created() {
      if(this.group) {
        this.entry.group = this.group.name;
      }
    },
    methods: {
      autoComplete,
      validate() {
        if(this.createForm.validate()) {
          this.createReadingInfo();
        }
      },
      close() {
        this.localDialog = false;
      },
      clear() {
        this.entry = {
          group: "",
          devices: [],
        };
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      createReadingInfo() {
        this.loading = true;
        this.$emit("success", this.entry);
        setTimeout(() => { 
            this.dismiss();
        }, 200);
      },
      getDevices() {
        this.devices = [];
        this.loading = true;

        DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
          this.loading = false;

          this.devices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loading = false;  
        });
      },
    },
    watch: {
      localDialog(value: boolean) {
        if(value) {
          if(this.group) {
            this.entry.group = this.group.name;
          }
          if(this.device) {
            this.entry.devices = [this.device];
          }
          this.getDevices();
        } else {
          this.clear();
        }
      }
    },
    computed: {
      createForm(): any {
          return this.$refs.createForm;
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      requiredError(): any {
          return this.$t('required');
      },
    }
  })
