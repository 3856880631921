
  import Vue from 'vue'
  import FilterChipTemplate from './FilterChipTemplate.vue';
  import { autoComplete } from '@/utils/Util';
import UserService from '@/services/UserService';

  export default Vue.extend({
    name: 'UserFilter',

    components: {
      FilterChipTemplate
    },
    props: ['value', 'label', 'attributes', 'mandatory', 'always'],
    data: (_this: any): any => ({
      rules: [
          (v: any) => (!!v && Object.keys(v).length > 0) || _this.requiredError
      ],
      localUser: _this.value[_this.attributes['value']],
      localFilters: _this.value as any,
      menu: false,
      loading: false,
      users: [],
    }),
    created() {
      this.getUsers();
    },
    methods: {
      onSave() {
        this.localFilters[this.attributes['value']] = this.localUser;
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onReset() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onChipRemove() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.$emit('delete');
      },      
      autoComplete,
      getUsers() {
        this.users = [];
        this.loading = true;
        
        UserService.getAllCollectionPages({ tenant: { uuid: this.$root.$store.state.session.selectedTenant.uuid }}).then((users: any[]) => {
            this.loading = false;

            this.users = users;
        }).catch((err: any) => {
            console.log(err, err.response); 
            this.loading = false;     
        });
      },
    },
    watch: {
      value() {
        this.localFilters = { ...this.value };
        this.localUser = this.localFilters[this.attributes['value']];
      },
      menu() {
        if(!this.menu) {
          this.localUser = this.localFilters[this.attributes['value']];
        }
      },
      currentTenant() {
        this.users = [];
        this.localFilters = { ...this.value }; // TODO
        this.getUsers();
      }
    },
    computed: {
      filterRepr(): any {
        return this.localFilters[this.attributes['value']]?.email ?? '';
      },
      isValid(): any {
        return this.mandatory ? this.rules.reduce((acc: boolean, rule: any) => acc ? rule(this.localUser)===true : acc, true) : true;
      },
      requiredError(): any {
        return this.$t('required');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
