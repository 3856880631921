
  import Vue from 'vue'
  import ConnectorsTable from '@/components/entities/connector/ConnectorsTable.vue'
  import InfoCard from '@/components/misc/InfoCard.vue'
  import ConnectorCreateDialog from '@/components/entities/connector/ConnectorCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import ConnectorService from '@/services/ConnectorService';
  import { extractErrorMessage } from '@/utils/Util'

  export default Vue.extend({
    name: 'ConnectorsView',

    components: {
      ConnectorsTable,
      ConnectorCreateDialog,
      InfoCard,
      EntityFiltersCard
    },
    data: () => ({
      connectors: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
      filtersConfig: [
        { attribute: 'name', always: true },
        { attribute: 'tag', always: true },
        { attribute: 'createdAt', always: false }
      ]
    }),
    created() {
      this.updateConnectors();
    },
    watch: {
      currentTenant() {
        this.filters = {};
        this.updateConnectors();
      }
    },
    methods: {
      onTableReload() {
        this.updateConnectors();
      },
      onConnectorFiltersApply() {
        this.updateConnectors();
      },
      onConnectorItemUpdate(item: any) {
        this.connectors.splice(this.connectors.findIndex((conn: any) => conn.uuid === item.uuid), 1);
        this.connectors.push(item);
      },
      onConnectorItemDelete(item: any) {
        this.connectors.splice(this.connectors.findIndex((conn: any) => conn.uuid === item.uuid), 1);
      },
      onConnectorCreation(connector: any) {
        this.connectors.push(connector);
      },
      updateConnectors() {
        this.connectors = [];
        this.dataLoaded = false;

        let params = {};

        Object.assign(params, this.filters);

        ConnectorService.getAllCollectionPages(this.currentTenant, params).then((connectors: any[]) => {
          this.dataLoaded = true;

          this.connectors = connectors;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('connector.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;   
          this.dataLoaded = true;  
        });
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'connectors' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['connectors'].includes('POST');
        } else {
          return false;
        }
      },      
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.connectors.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
