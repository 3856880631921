
  import Vue from 'vue'
  import FilterChipTemplate from './FilterChipTemplate.vue';
  import { autoComplete } from '@/utils/Util';

  export default Vue.extend({
    name: 'TenantFilter',

    components: {
      FilterChipTemplate
    },
    props: ['value', 'label', 'attributes', 'mandatory', 'always'],
    data: (_this: any): any => ({
      rules: [
        (v: any) => (!!v && Object.keys(v).length > 0) || _this.requiredError
      ],
      localTenant: _this.value[_this.attributes['value']],
      localFilters: _this.value as any,
      menu: false,
      loading: false,
    }),
    created() {
      // s
    },
    methods: {
      onSave() {
        this.localFilters[this.attributes['value']] = this.localTenant;
        this.$emit('input', { ...this.localFilters });
        console.log(this.localFilters)
        this.menu = false;
      },
      onReset() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onChipRemove() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.$emit('delete');
      },      
      autoComplete,
    },
    watch: {
      value() {
        this.localFilters = { ...this.value };
        this.localTenant = this.localFilters[this.attributes['value']];
      },
      menu() {
        if(!this.menu) {
          this.localTenant = this.localFilters[this.attributes['value']];
        }
      },
      currentTenant() {
        // s
      }
    },
    computed: {
      isValid(): any {
        return this.mandatory ? this.rules.reduce((acc: boolean, rule: any) => acc ? rule(this.localTenant)===true : acc, true) : true;
      },
      requiredError(): any {
        return this.$t('required');
      },
      filterRepr(): any {
        return this.localFilters[this.attributes['value']]?.name ?? '';
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
