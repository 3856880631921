
  import Vue from 'vue'
  import Chart from '@/components/visualization/Chart.vue';
import IoTDeviceService from '@/services/IoTDeviceService';

  export default Vue.extend({
    name: 'IotDeviceMonitoringGraphDialog',

    components: {
        Chart,
    },
    props: ['iotdevice', 'timeRangeSelection'],
    data: (vm: any): any => ({
        localdialog: false,
        loading: false,
        selectedChartType: 'scatter',
        readings: [] as any[],
        chartOptions: {
            barThickness: 1,
            elements: {
                point: {
                    pointStyle: 'cross',
                    radius: 3,
                    backgroundColor: '#3188e5',
                    borderColor: '#3188e5'
                },
                bar: {
                    backgroundColor: '#3188e5',
                    borderColor: '#3188e5'

                }
            },
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x'
                    }
                },
            }
        }
    }),
    created() {
    },
    async mounted() {
        await this.getReadings();
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        // this.readings = [];
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async getReadings() {
        this.readings = [];
        this.loading = true;

        let date = new Date(Date.now());

        let params: any = {};
        params.until = date.toISOString();

        if(this.timeRangeSelection === '1D') {
            date.setDate(date.getDate()-1);
        } else if(this.timeRangeSelection === '1W') {
            date.setDate(date.getDate()-7);
        } else if(this.timeRangeSelection === '1M') {
            date.setMonth(date.getMonth()-1);
        } else if(this.timeRangeSelection === '3M') {
            date.setMonth(date.getMonth()-3);
        }
        params.from = date.toISOString();

        try {
            this.readings = await IoTDeviceService.getAllMonitoringReadingPages(this.iotdevice.uuid, params);
            this.loading = false;
        } catch(err: any) {
            console.log(err, err.response);
            this.loading = false;
        }
      }
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                //s
            } else {
                //s
            }
        }
    },
    computed: {
    }
  })
