
  import Vue from 'vue'
  import Dashboard from '@/components/Dashboard.vue'
  import GroupCreateDialog from '@/components/dashboard/GroupCreateDialog.vue'

  import { v4 as uuidv4 } from 'uuid';

  import DashboardService from '@/services/DashboardService';
import DeviceService from '@/services/DeviceService';

  export default Vue.extend({
    name: 'DashboardView',

    components: {
      Dashboard,
      GroupCreateDialog
    },
    async created() {
      await this.getDevices();
      await this.getDashboardEntries();
      window.addEventListener('beforeunload', this.onPageUnload);
    },
    destroyed() {
      clearInterval(this.refreshInterval);
      window.removeEventListener('beforeunload', this.onPageUnload);
    },
    activated() {
      this.refreshInterval = setInterval(this.getDashboardEntries, 15*60*1000);
    },
    deactivated() {
      if(this.refreshInterval)
        clearInterval(this.refreshInterval);
    },
    beforeRouteLeave(to, from, next) {
      if(this.unsavedChanges) {
        this.showSaveChangesOverlay = true;
        this.waitForOverlayResponse().then((res: any) => {
          next(res)
        });
      } else {
        next();
      }
    },
    data: () => ({
      loading: false,
      unsavedChanges: false,
      showSaveChangesOverlay: false,
      unsavedChangesOverlayResolve: null as any,
      saveLoading: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      dashboardEntries: [] as any[],
      dashboardUUID: "",
      refreshInterval: undefined as any,
      devices: [] as any[]
    }),
    watch: {
      async currentTenant() {
        this.reset();
        await this.getDevices();
        await this.getDashboardEntries();
      },
    },
    methods: {
      reset() {
        this.unsavedChanges = false;
        this.showSaveChangesOverlay = false;
        this.unsavedChangesOverlayResolve = null;
        this.dataError = false;
        this.dashboardEntries= [];
        this.dashboardUUID = "";
        this.devices = [];
      },
      async onPageUnload(event: any) {
        if(this.unsavedChanges) {
          event.preventDefault()
          // Chrome requires returnValue to be set.
          event.returnValue = ""
        } 
      },
      async saveOverlayChanges() {
        this.showSaveChangesOverlay = false;
        await this.saveDashboardEntries();
        this.overlayResponse(true);
      },
      async discardOverlayChanges() {
        this.showSaveChangesOverlay = false;
        await this.getDashboardEntries();
        this.unsavedChanges = false;
        this.overlayResponse(true);
      },
      cancelOverlayChanges() {
        this.showSaveChangesOverlay = false;
        this.overlayResponse(false);
      },
      waitForOverlayResponse() {
        return new Promise(resolve => {
          this.unsavedChangesOverlayResolve = resolve;
        })
      },
      overlayResponse(response: boolean) {
        this.unsavedChangesOverlayResolve(response)
      },
      onGroupCreate(name: string) {
        this.dashboardEntries.push({
          uuid: uuidv4(),
          name: name,
          open: true,
          edit: false,
          items: [],
        });
        this.unsavedChanges= true;
      },
      onGroupUpdate(group: any) {
        this.dashboardEntries.splice(this.dashboardEntries.findIndex((item: any) => item.uuid === group.uuid), 1, group);
        this.unsavedChanges= true;
      },
      onGroupDelete(group: any) {
        this.dashboardEntries.splice(this.dashboardEntries.findIndex((item: any) => item.uuid === group.uuid), 1);
        this.unsavedChanges= true;
      },
      onCardCreate(item: any) {
        for(let i=0; i<this.dashboardEntries.length; i++) {
          if(this.dashboardEntries[i].uuid === item.group) { 
            for(let di=0; di<item.devices.length; di++) {    
              this.dashboardEntries[i].items.push({
                uuid: uuidv4(),
                group: item.group,
                position: undefined,
                type: 'reading-card',
                device: item.devices[di],
                offline: false
              });
            }
            break;
          }
        }
        this.unsavedChanges= true;
      },
      onChartCreate(item: any) {
        for(let i=0; i<this.dashboardEntries.length; i++) {
          if(this.dashboardEntries[i].uuid === item.group) { 
            for(let di=0; di<item.devices.length; di++) {    
              this.dashboardEntries[i].items.push({
                uuid: uuidv4(),
                group: item.group,
                position: undefined,
                type: 'device-chart',
                config: { type: 'bar', timeRange: { value: "1m", label: "1 " + this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" }, function: 'Max' },
                device: item.devices[di],
                offline: false
              });
            }
            break;
          }
        }
        this.unsavedChanges= true;
      },
      onMultiChartCreate(group: any) {
        for(let i=0; i<this.dashboardEntries.length; i++) {
          if(this.dashboardEntries[i].uuid === group.uuid) { 
            this.dashboardEntries[i].items.push({
              uuid: uuidv4(),
              group: group.uuid,
              position: undefined,
              type: 'multi-device-chart',
              config: {},
              offline: false
            });
            break;
          }
        }
        this.unsavedChanges= true;
      },
      onItemDelete(item: any) {
        console.log(item);
        for(let i=0; i<this.dashboardEntries.length; i++) {
          const index = this.dashboardEntries[i].items.findIndex((itm: any) => itm.uuid === item.uuid);
          if(index >= 0)
            this.dashboardEntries[i].items.splice(index, 1);
        }
        this.unsavedChanges= true;
      },
      onItemUpdate(item: any) {
        for(let i=0; i<this.dashboardEntries.length; i++) {
          const index = this.dashboardEntries[i].items.findIndex((itm: any) => itm.uuid === item.uuid);
          if(index >= 0)
            this.dashboardEntries[i].items.splice(index, 1, item);
        }
        this.unsavedChanges= true;
      },
      async getDevices() {
          this.devices = [];
          this.loading = true;

          try {
            const devices = await DeviceService.getAllCollectionPages(this.currentTenant);
            this.loading = false;
            this.devices = devices;
          } catch(err: any) {
            console.log(err, err.response);  
            this.loading = false; 
          }
      },
      async getDashboardEntries() {
        this.dashboardEntries= [];
        this.dashboardUUID = "";
        this.loading = true;

        try {
          console.log('getDashboardEntries')
          const dashboards = await DashboardService.getAllDashboardCollectionPages(this.currentTenant, this.currentUser.uuid);

          const defaultIndex = dashboards.findIndex((value: any) => value.name === 'default');
          if(defaultIndex >= 0) {
            this.dashboardUUID = dashboards[defaultIndex].uuid;
            this.dashboardEntries = dashboards[defaultIndex].config.groups ?? [];
          } else {
            console.log('No default dashboard found.')
          }

          for(let i=0; i<this.dashboardEntries.length; i++) {
            if(!('uuid' in this.dashboardEntries[i]))
              this.dashboardEntries[i] = {uuid: uuidv4(), ...this.dashboardEntries[i]};
            
            for(let z=0;z<this.dashboardEntries[i].items.length;z++) {
              const item = this.dashboardEntries[i].items[z];
              // update devices object in entries
              if(item.type === 'multi-device-chart') {
                for(let j=0;j<item.config.selection.length;j++) {
                  const device = this.devices.find((value: any) => value.uuid === item.config.selection[j]?.device?.uuid);
                  if(device)
                    item.config.selection[j].device = device;
                }
              } else {
                const device = this.devices.find((value: any) => value.uuid === item?.device?.uuid);
                if(device)
                  item.device = device;
              }
            }

          }
          console.log(this.dashboardEntries)

          this.loading = false;
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = err;
          this.dataError = true;   
          this.loading = false;  
        }
      },
      async saveDashboardEntries() {
        this.saveLoading = true;
        try {
          console.log('saveDashboardEntries')
          console.log(this.dashboardUUID)
          if(this.dashboardUUID) {
            await DashboardService.updateDashboard(this.currentTenant, this.currentUser.uuid, this.dashboardUUID, undefined, { groups: this.dashboardEntries });
          } else {
            const dashboard = await DashboardService.createDashboard(this.currentTenant, this.currentUser.uuid, 'default', { groups: this.dashboardEntries });
            this.dashboardUUID = dashboard.uuid;
          }
          this.saveLoading = false;
          this.unsavedChanges = false;
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = err;
          this.dataError = true; 
          this.saveLoading = false;
        }
      },    
      delay(ms: number) {
        return new Promise(res => setTimeout(res, ms));
      },
    },
    computed: {
      currentUser(): any {
        return this.$root.$store.state.session.currentUser;
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
