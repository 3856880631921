
  import Vue from 'vue'
  import FilterChipTemplate from './FilterChipTemplate.vue';

  export default Vue.extend({
    name: 'AggregationIntervalFilter',

    components: {
      FilterChipTemplate
    },
    props: ['value', 'label', 'attributes', 'mandatory', 'always'],
    data: (_this: any): any => ({
      rules: [
        (v: string) => !!v || _this.requiredError
      ],
      localNumber: _this.value[_this.attributes['value']],
      localFilters: _this.value as any,
      menu: false,
      disabled: false,
    }),
    created() {
      if(this.localFilters.aggregationFrequency && 
        (this.localFilters.aggregationFrequency === 'monthly' || 
         this.localFilters.aggregationFrequency === 'monthly')) {
          this.disabled = true;
          this.localNumber = 1;
      } else {
        this.disabled = false;
      }
    },
    methods: {
      onSave() {
        this.localFilters[this.attributes['value']] = this.localNumber;
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onReset() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onChipRemove() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.$emit('delete');
      }
    },
    watch: {
      value() {
        this.localFilters = { ...this.value };
        this.localDevice = this.localFilters[this.attributes['value']];
        // handle when specific aggregation frequencies are selected
        if(this.localFilters.aggregationFrequency && 
        (this.localFilters.aggregationFrequency === 'monthly' || 
         this.localFilters.aggregationFrequency === 'yearly')) {
          this.disabled = true;
          this.localNumber = 1;
        } else {
          this.disabled = false;
        }
      },
      menu() {
        if(!this.menu) {
          this.localNumber = this.localFilters[this.attributes['value']];
        }
      }
    },
    computed: {
      filterRepr(): any {
        return this.localFilters[this.attributes['value']] ?? '';
      },
      isValid(): any {
        return this.mandatory ? this.rules.reduce((acc: boolean, rule: any) => acc ? rule(this.localNumber)===true : acc, true) : true;
      },
      requiredError(): any {
        return this.$t('required');
      },
    }
  })
