
    import Vue from 'vue'

    import Chart from '@/components/visualization/Chart.vue';
    import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
    import ReadingService from '@/services/ReadingService';
    import { autoComplete } from '@/utils/Util';

    export default Vue.extend({
        name: 'ChartCard',

        components: {
            Chart,
            DeviceInfoDialog
        },
        props: ['item', 'index'],
        data: (vm: any): any => ({
            loadingDevices: false,
            requiredSelect: [
                (v: any) => (!!v && Object.keys(v).length > 0) || vm.requiredError
            ],
            loading: false,
            chartData: [],
            selectedChartType: vm?.item?.config?.type || "bar",
            selectedTimeRange: vm?.item?.config?.timeRange || { value: "1m", label: "1 " + vm.$t('timeUnit.month'), interval: "1", frequency: "DAILY" },
            selectedFunction: vm?.item?.config?.function || "Max",
            timeFunctions: ["Avg", "Sum", "Min", "Max", "Diff"]
        }),
        async created() {
            if(this.index && parseInt(this.index))
                await this.delay(parseInt(this.index)*100);
            this.getData();
        },
        watch: {
            selectedChartType(): any {
                this.onConfigChange();
            },
            selectedTimeRange(): any {
                this.onConfigChange();
                this.getData();
            },
            selectedFunction(): any {
                this.onConfigChange();
                this.getData();
            },
        },
        methods: {
            delay(ms: number) {
                return new Promise(res => setTimeout(res, ms));
            },
            onChartDownload() {
                // s
            },
            onDelete() {
                this.$emit('delete', this.item);
            },
            onRefresh() {
                this.getData();
            },
            onShowReadings() {
                this.$router.push({ name: 'Readings', params: { device: this.item.device } });
            },    
            onConfigChange() {
                let citem = { ...this.item }
                citem.config = { type: this.selectedChartType, timeRange: this.selectedTimeRange, function: this.selectedFunction };
                this.$emit('update:item', citem);
            },
            autoComplete,
            async getData() {
                this.loading = true;

                let aggregationInterval = this.selectedTimeRange.interval;
                let aggregationFrequency = this.selectedTimeRange.frequency;
                let aggregationFunction = this.selectedFunction;
                let from = new Date();
                
                if(this.selectedTimeRange.value === "1h") {
                    from.setHours(from.getHours()-1);
                } else if(this.selectedTimeRange.value === "6h") {
                    from.setHours(from.getHours()-6);
                } else if(this.selectedTimeRange.value === "12h") {
                    from.setHours(from.getHours()-12);
                } else if(this.selectedTimeRange.value === "1d") {
                    from.setDate(from.getDate()-1);
                } else if(this.selectedTimeRange.value === "1w") {
                    from.setDate(from.getDate()-7);
                } else if(this.selectedTimeRange.value === "1m") {
                    from.setMonth(from.getMonth()-1);
                } else if(this.selectedTimeRange.value === "6m") {
                    from.setMonth(from.getMonth()-6);
                } else if(this.selectedTimeRange.value === "1y") {
                    from.setFullYear(from.getFullYear()-1);
                } else if(this.selectedTimeRange.value === "3y") {
                    from.setFullYear(from.getFullYear()-3);
                }

                try {
                    this.chartData = await ReadingService.getAllAggregationPages(this.currentTenant, this.item.device.uuid, aggregationInterval, aggregationFrequency, aggregationFunction, { from: from.toISOString() });
                    // console.log(this.chartData);
                } catch(error) {
                    console.log(error);
                }
                this.loading = false;
            }
        },
        computed: {
            requiredError(): any {
                return this.$t('required');
            },
            currentTenant(): any {
                return this.$root.$store.state.session.selectedTenant.uuid;
            },
            timeRanges(): any[] {
                return [
                { value: "1h", label: "1 " + this.$t('timeUnit.hours'), interval: "1", frequency: "MINUTELY" },
                { value: "6h", label: "6 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "12h", label: "12 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "1d", label: "1 " + this.$t('timeUnit.day'), interval: "1", frequency: "HOURLY" },
                { value: "1w", label: "1 " + this.$t('timeUnit.week'), interval: "1", frequency: "DAILY" },
                { value: "1m", label: "1 " + this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" },
                { value: "6m", label: "6 " + this.$t('timeUnit.months'), interval: "1", frequency: "WEEKLY" },
                { value: "1y", label: "1 " + this.$t('timeUnit.year'), interval: "1", frequency: "MONTHLY" },
                { value: "3y", label: "3 " + this.$t('timeUnit.years'), interval: "1", frequency: "MONTHLY" },
            ];
            }
        },
    })
