
    import Vue from 'vue'

    import MultiChart from '@/components/visualization/MultiChart.vue';
    import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
    import ReadingService from '@/services/ReadingService';
    import DeviceService from '@/services/DeviceService';
    import { autoComplete } from '@/utils/Util';
    import { ReadingAggregationFunctions } from '../entities/reading/ReadingCollections';

    import domtoimage from 'dom-to-image';

    export default Vue.extend({
        name: 'ExtendedChartCard',

        components: {
            MultiChart,
            DeviceInfoDialog
        },
        props: ['config'],
        data: (_this: any): any => ({
            devices: [] as any[],
            loadingDevices: false,
            requiredSelect: [
                (v: any) => (!!v && Object.keys(v).length > 0) || _this.requiredError
            ],
            loading: false,
            downloadLoading: false,
            selectedChartType: _this.config.selectedChartType ?? "bar",
            selectedTimeRange: _this.config.selectedTimeRange ?? { value: "1m", label: "1 " + _this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" },
            timeFunctions: ReadingAggregationFunctions,
            selection: _this.config.selection ?? [],
            selectionData: [] as any[],
        }),
        created() {
            this.getDevices();
            if(this.config) {
                this.getData();
            }
        },
        watch: {
            selectedChartType(): any {
                this.configUpdate();
            },
            selectedTimeRange(): any {
                this.getData();
                this.configUpdate();
            },
            currentTenant() {
                this.selectionData = [];
                this.selection = this.config.selection ?? [];
                this.selectedChartType = this.config.selectedChartType ?? "bar";
                this.selectedTimeRange = this.config.selectedTimeRange ?? { value: "1m", label: "1 " + this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" };
                this.getDevices();
                if(this.config) {
                    this.getData();
                }
            },
        },
        methods: {
            onSelectionReset() {
                this.selectionData = [];
                this.selection = [];
                this.configUpdate();
            },
            onShare() {
                // TODO save the config to dashboard entries and load dashboard
            },
            onRefresh() {
                this.getData();
            },
            configUpdate() {
                this.$emit('update', { selection: this.selection, selectedTimeRange: this.selectedTimeRange,  selectedChartType: this.selectedChartType });
            },
            onDeleteDevice(index: number) {
                this.selection.splice(index, 1);
                this.selectionData.splice(index, 1);
            },
            onAddDevice() {
                this.selection.push({ device: undefined, function: { name: "Last", value: "last" }, edit: true });
            },
            onSaveDevice(index: number) {
                this.selection[index].edit = false; 
                this.getDeviceData(index);
                this.configUpdate();
            },
            onDownload() {
                this.downloadLoading = true;
                domtoimage.toPng(this.$refs.chartCard)
                    .then((dataUrl: any) => {
                        this.downloadLoading = false;
                        let link = document.createElement("a");
                        link.setAttribute("href", dataUrl);
                        link.setAttribute("download", 'BAScloud_chart.png');
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch((error: any) => {
                        this.downloadLoading = false;
                        console.error(error);
                    });
            },
            autoComplete,
            getDevices() {
                this.devices = [];
                this.loadingDevices = true;

                DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
                    this.loadingDevices = false;

                    this.devices = devices;
                }).catch((err: any) => {
                    console.log(err, err.response);  
                    this.loadingDevices = false;    
                });
            },
            async getDeviceData(index: number) {
                this.loading = true;

                let aggregationInterval = this.selectedTimeRange.interval;
                let aggregationFrequency = this.selectedTimeRange.frequency;
                let aggregationFunction = this.selection[index].function.value;
                let from = new Date();
                
                if(this.selectedTimeRange.value === "1h") {
                    from.setHours(from.getHours()-1);
                } else if(this.selectedTimeRange.value === "6h") {
                    from.setHours(from.getHours()-6);
                } else if(this.selectedTimeRange.value === "12h") {
                    from.setHours(from.getHours()-12);
                } else if(this.selectedTimeRange.value === "1d") {
                    from.setDate(from.getDate()-1);
                } else if(this.selectedTimeRange.value === "1w") {
                    from.setDate(from.getDate()-7);
                } else if(this.selectedTimeRange.value === "1m") {
                    from.setMonth(from.getMonth()-1);
                } else if(this.selectedTimeRange.value === "6m") {
                    from.setMonth(from.getMonth()-6);
                } else if(this.selectedTimeRange.value === "1y") {
                    from.setFullYear(from.getFullYear()-1);
                } else if(this.selectedTimeRange.value === "3y") {
                    from.setFullYear(from.getFullYear()-3);
                }

                try {
                    const data = await ReadingService.getAllAggregationPages(this.currentTenant, this.selection[index].device.uuid, aggregationInterval, aggregationFrequency, aggregationFunction, { from: from.toISOString() });

                    if(index < this.selectionData.length) {
                        this.selectionData.splice(index, 1, { device: this.selection[index].device, data: data });
                    } else {
                        this.selectionData.push({ device: this.selection[index].device, data: data });
                    }
                } catch(error) {
                    console.log(error);
                }
                this.loading = false;
            },
            async getData() {
                for (let i = 0; i < this.selection.length; i++) {
                    await this.getDeviceData(i);
                }
            }
        },
        computed: {
            chartLabels(): any[] {
                return this.selectionData.map((value: any) => (value.device.description.length > 70 ? value.device.description.substring(0, 70)+'...' : value.device.description) + ', ' + value.device.unit);
            },
            chartData(): any[] {
                return this.selectionData.map((value: any) => value.data);
            },
            inEdit(): any {
                return this.selection.reduce((acc: boolean, value: any) => acc || value.edit, false);
            },
            requiredError(): any {
                return this.$t('required');
            },
            currentTenant(): any {
                return this.$root.$store.state.session.selectedTenant.uuid;
            },
            timeRanges(): any[] {
                return [
                { value: "1h", label: "1 " + this.$t('timeUnit.hours'), interval: "1", frequency: "MINUTELY" },
                { value: "6h", label: "6 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "12h", label: "12 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "1d", label: "1 " + this.$t('timeUnit.day'), interval: "30", frequency: "MINUTELY" },
                { value: "1w", label: "1 " + this.$t('timeUnit.week'), interval: "12", frequency: "HOURLY" },
                { value: "1m", label: "1 " + this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" },
                { value: "6m", label: "6 " + this.$t('timeUnit.months'), interval: "1", frequency: "WEEKLY" },
                { value: "1y", label: "1 " + this.$t('timeUnit.year'), interval: "1", frequency: "MONTHLY" },
                { value: "3y", label: "3 " + this.$t('timeUnit.years'), interval: "1", frequency: "MONTHLY" },
            ];
            }
        },
    })
