
  import Vue from 'vue'

  import ReadingCard from '@/components/dashboard/ReadingCard.vue'
  import ReadingCardCreateDialog from '@/components/dashboard/ReadingCardCreateDialog.vue'
  import DeviceChartCreateDialog from './dashboard/DeviceChartCreateDialog.vue'
  import ChartCard from './dashboard/ChartCard.vue'
  import MultiChartCard from './dashboard/MultiChartCard.vue'

  export default Vue.extend({
    name: 'Dashboard',

    props: ['data', 'loading'],
    components: {
      ReadingCard,
      ReadingCardCreateDialog,
      DeviceChartCreateDialog,
      ChartCard,
      MultiChartCard
    },
    data: () => ({
    }),
    created() {
      // e
    },
    methods: {
      onReadingCardCreate(items: any) {
        // console.log(items);
        this.$emit('create:card', items);
      },
      onDeviceChartCreate(items: any) {
        // console.log(items);
        this.$emit('create:chart', items);
      },
      onMultiChartCreate(group: any) {
        // console.log(items);
        this.$emit('create:multi-chart', group);
      },
      onDeleteGroup(group: any) {
        group.edit = !group.edit;
        this.$emit('delete:group', group);
      },
      onUpdateGroup(group: any) {
        group.edit = !group.edit;
        this.$emit('update:group', group);
      }
    },
    computed: {
    }
  })
