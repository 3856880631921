
  import Vue from 'vue'
  import FilterChipTemplate from './FilterChipTemplate.vue';
  import DateTimePicker from '@/components/misc/DateTimePicker.vue'
  import DateRangePicker from '../misc/DateRangePicker.vue';
  import DatePicker from '../misc/DatePicker.vue';
  import MonthDatePicker from '../misc/MonthDatePicker.vue';

  export default Vue.extend({
    name: 'TimeRangeFilter',

    components: {
      FilterChipTemplate,
      DateTimePicker,
      DateRangePicker,
      DatePicker,
      MonthDatePicker
    },
    props: ['value', 'label', 'attributes', 'mandatory', 'always'],
    data: (_this: any): any => ({
      localFrom: _this.value[_this.attributes['from']],
      localUntil: _this.value[_this.attributes['until']],
      localFilters: _this.value as any,
      menu: false,
      dateOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      selectionOption: "today",
      lastTimeRanges: [
          { label: "1 Hour", frequency:"hour", offset: "1"},
          { label: "6 Hour", frequency:"hour", offset: "6"},
          { label: "12 Hour", frequency:"hour", offset: "12"},
          { label: "1 Day", frequency:"day", offset: "1"},
          { label: "1 Week", frequency:"day", offset: "7"},
          { label: "1 Month", frequency:"month", offset: "1"},
          { label: "6 Months", frequency:"month", offset: "6"},
          { label: "1 Year", frequency:"year", offset: "1", },
          { label: "3 Years", frequency:"year", offset: "3" }
      ],
      lastTimeRangeSelection: undefined,
      dateRangeSelection: undefined,
      localYear: undefined
    }),
    created() {
      // s
    },
    methods: {
      onSave() {
        let from, until;

        if(this.selectionOption === 'today') {
          let date = new Date();
          date.setUTCHours(0, 0, 0, 0);
          from = date.toISOString();
          date.setDate(date.getDate()+1);
          until = date.toISOString();
        } else if(this.selectionOption === 'last') {
          let date = new Date();
          until = date.toISOString();
          if(this.lastTimeRangeSelection.frequency === 'hour') {
            date.setHours(date.getHours()-this.lastTimeRangeSelection.offset);
          }else if(this.lastTimeRangeSelection.frequency === 'day') {
            date.setDate(date.getDate()-this.lastTimeRangeSelection.offset);
          } else if(this.lastTimeRangeSelection.frequency === 'month') {
            date.setMonth(date.getMonth()-this.lastTimeRangeSelection.offset);
          } else if(this.lastTimeRangeSelection.frequency === 'year') {
            date.setFullYear(date.getFullYear()-this.lastTimeRangeSelection.offset);
          }
          from = date.toISOString();
        } else if(this.selectionOption === 'date') {
          let date = new Date(this.localFrom);
          from = date.toISOString();

          date.setUTCDate(date.getUTCDate()+1);
          until = date.toISOString();
        } else if(this.selectionOption === 'month') {
          let date = new Date(this.localFrom);
          from = date.toISOString();

          date.setMonth(date.getMonth()+1);
          until = date.toISOString();
        } else if(this.selectionOption === 'year') {
          let date = new Date(this.localYear, 0, 1, 12); // set it to 12hr to be sure its utc 0:00
          date.setUTCHours(0, 0, 0, 0);
          from = date.toISOString();
          date.setFullYear(date.getFullYear()+1);
          until = date.toISOString();
        } else if(this.selectionOption === 'betweenDates') {
          let date = new Date(this.dateRangeSelection[0]);
          from = date.toISOString();
          date = new Date(this.dateRangeSelection[1]);
          until = date.toISOString();
        } else if(this.selectionOption === 'betweenDatetimes') {
          from = this.localFrom;
          until = this.localUntil;
        }
        this.localFilters[this.attributes['from']] = from;
        this.localFilters[this.attributes['until']] = until;

        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onReset() {
        delete this.localFilters[this.attributes['from']];
        delete this.localFilters[this.attributes['until']];
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onChipRemove() {
        delete this.localFilters[this.attributes['from']];
        delete this.localFilters[this.attributes['until']];
        this.$emit('input', { ...this.localFilters });
        this.$emit('delete');
      },
      dateString(dateStr: string) {
        if(dateStr) {
          const date = new Date(dateStr);
          return date.toLocaleDateString(this.dateOptions);
        }
        return "";
      }
    },
    watch: {
      selectionOption() {
        this.localFrom = undefined;
        this.localUntil = undefined;
      },
      value() {
        this.localFilters = { ...this.value };
        this.localFrom = this.localFilters[this.attributes['from']];
        this.localUntil = this.localFilters[this.attributes['until']];
      },
      menu() {
        if(!this.menu) {
          this.localFrom = this.localFilters[this.attributes['from']];
          this.localUntil = this.localFilters[this.attributes['until']];
        }
      }
    },
    computed: {
      fromRepr(): any {
        return this.dateString(this.localFilters[this.attributes['from']]);
      },
      untilRepr(): any {
        return this.dateString(this.localFilters[this.attributes['until']]);
      },
      filterRepr(): any {
        const divider = this.fromRepr && this.untilRepr ? ' - ' : '';
        return this.fromRepr + divider + this.untilRepr;
      },
      years(): any[] {
        const currentYear = new Date(Date.now()).getFullYear();
        let yl = [];
        for (let i = currentYear-10; i <= currentYear; i++) {
            yl.push(i);
        }
        return yl;
      },
    }
  })
