import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(tenantUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties');
    },
    async getAllCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties?';

            //  Filters
            if('name' in params && params.name && params.name.length > 0) {
                requestURL = requestURL + '&name=' + encodeURIComponent(params.name);
            }
            if('aksId' in params && params.aksId && params.aksId.length > 0) {
                requestURL = requestURL + '&aksId=' + encodeURIComponent(params.aksId);
            }
            if('identifier' in params && params.identifier && params.identifier.length > 0) {
                requestURL = requestURL + '&identifier=' + encodeURIComponent(params.identifier);
            }
            if('street' in params && params.street && params.street.length > 0) {
                requestURL = requestURL + '&street=' + encodeURIComponent(params.street);
            }
            if('postalCode' in params && params.postalCode && params.postalCode.length > 0) {
                requestURL = requestURL + '&postalCode=' + encodeURIComponent(params.postalCode);
            }
            if('city' in params && params.city && params.city.length > 0) {
                requestURL = requestURL + '&city=' + encodeURIComponent(params.city);
            }
            if('country' in params && params.country && params.country.length > 0) {
                requestURL = requestURL + '&country=' + encodeURIComponent(params.country);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('tag' in params && params.tag && Object.keys(params.tag).length > 0) {
                requestURL = requestURL + '&tagId=' + encodeURIComponent(params.tag.uuid);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    createProperty(tenantUUID: string, name: string, aksId = "", 
        identifier = "", street = "", postalCode = "", city = "", country = ""): Promise<any> {
    
        const body = {
            data: {
                type: "properties",
                attributes: {
                    name: name
                }
            }
        } as any;

        if(aksId.length > 0) {
            body.data.attributes.aksId = aksId;
        }
        if(identifier.length > 0) {
            body.data.attributes.identifier = identifier;
        }
        if(street.length > 0) {
            body.data.attributes.street = street;
        }
        if(postalCode.length > 0) {
            body.data.attributes.postalCode = postalCode;
        }
        if(city.length > 0) {
            body.data.attributes.city = city;
        }
        if(country.length > 0) {
            body.data.attributes.country = country;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateProperty(tenantUUID: string, propertyUUID: string, name = undefined, aksId = undefined, 
        identifier = undefined, street = undefined, postalCode = undefined, city = undefined, country = undefined): Promise<any> {
        
        const body = {
            data: {
                type: "properties",
                id: propertyUUID,
                attributes: {
                }
            }
        } as any;

        if(name !== undefined) {
            body.data.attributes.name = name;
        }
        if(aksId !== undefined) {
            body.data.attributes.aksId = aksId;
        }
        if(identifier !== undefined) {
            body.data.attributes.identifier = identifier;
        }
        if(street !== undefined) {
            body.data.attributes.street = street;
        }
        if(postalCode !== undefined) {
            body.data.attributes.postalCode = postalCode;
        }
        if(city !== undefined) {
            body.data.attributes.city = city;
        }
        if(country !== undefined) {
            body.data.attributes.country = country;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getSingle(tenantUUID: string, propertyUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID);
    },
    getAssociatedDevices(tenantUUID: string, propertyUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID + '/devices');
    },
    getAssociatedTags(tenantUUID: string, propertyUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID + '/tags');
    },
    addTags(tenantUUID: string, propertyUUID: string, tags: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const tag of tags) {
            body.data.push({
                type: "tags",
                id: tag.uuid
            });
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID + '/tags', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeTags(tenantUUID: string, propertyUUID: string, tags: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const tag of tags) {
            body.data.push({
                type: "tags",
                id: tag.uuid
            });
        }
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID +  '/properties/' + propertyUUID + '/tags', { headers: {'Content-Type': 'application/vnd.api+json'}, data: JSON.stringify(body)});
    },
    deleteProperty(tenantUUID: string, propertyUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID);
    },
    getAssociatedAlerts(tenantUUID: string, propertyUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID + '/alerts');
    },
    addAlert(tenantUUID: string, propertyUUID: string, recipients: string[], maxAge: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "type": "alerts",
                "attributes": {
                    "maxAge": maxAge,
                    "recipients": recipients
                }
            }
        } as any;

        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID + '/alerts', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateAlert(tenantUUID: string, propertyUUID: string, alertUUID: string, recipients?: string[], maxAge?: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "id": alertUUID,
                "type": "alerts",
                "attributes": {
                }
            }
        } as any;

        if(recipients && recipients.length > 0) {
            body.data.attributes.recipients = recipients;
        }
        if(maxAge) {
            body.data.attributes.maxAge = maxAge;
        }
        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID + '/alerts/' + alertUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeAlert(tenantUUID: string, propertyUUID: string, alertUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/properties/' + propertyUUID + '/alerts/' + alertUUID);
    },
}
  