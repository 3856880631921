
    import Vue from 'vue'

    export default Vue.extend({
        name: 'FilterChipTemplate',

        components: {
        },
        props: ['label', 'text', 'mandatory', 'always', 'menu', 'error'],
        data: (_this: any): any => ({
            localMenu: _this.menu,
        }),
        mounted() {
            // hack to get the inputs in the menus to validate
            if(this.mandatory) {
                this.localMenu = true;
                this.$nextTick(() => {
                    this.localMenu = false;
                });
            }
        },
        methods: {
            onReset() {
                this.$emit('reset');
            },
            onSave() {
                this.$emit('save');
            },
            onDelete() {
                this.$emit('delete');
            }
        },
        watch: {
            mandatory() {
                if(this.mandatory) {
                    this.localMenu = true;
                    this.$nextTick(() => {
                        this.localMenu = false;
                    });
                }
            },
            menu() {
                this.localMenu = this.menu;
            },
            localMenu() {
                this.$emit('update:menu', this.localMenu);
            }
        },
        computed: {
        }
    })
