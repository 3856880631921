
  import Vue from 'vue'
  import ExtendedChartCard from './visualization/ExtendedChartCard.vue'
  import ChartCreateDialog from './visualization/ChartCreateDialog.vue';

  export default Vue.extend({
    name: 'Visualization',

    props: ['config'],
    components: {
      ExtendedChartCard,
      ChartCreateDialog
    },
    data: () => ({
    }),
    created() {
      // e
    },
    methods: {
      onSelectionUpdate(selection: any) {
        this.$emit('update', selection);
      }
    }
  })
